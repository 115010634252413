@import '../../style/themes/index';
@import '../../style/mixins/index';
@import '../../button/style/mixin';
@import './mixin';

@search-prefix: ~'@{ant-prefix}-input-search';
@search-rtl-cls: ~'@{search-prefix}-rtl';

.@{search-prefix} {
  &-rtl {
    direction: rtl;
  }
  &-icon {
    color: @text-color-secondary;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      color: @input-icon-hover-color;
    }
  }

  &-enter-button {
    input {
      border-right: 0;

      .@{search-rtl-cls}& {
        border: @border-width-base @border-style-base @input-border-color;
        border-left: 0;
      }
    }

    &.@{ant-prefix}-input-affix-wrapper {
      border-right: 0;
    }

    & + .@{ant-prefix}-input-group-addon,
    input + .@{ant-prefix}-input-group-addon {
      padding: 0;
      border: 0;

      .@{search-prefix}-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .@{search-rtl-cls}& {
        .@{search-prefix}-button {
          width: 100%;
          border-top-left-radius: @border-radius-base;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: @border-radius-base;
        }
      }
    }
  }
}
